.flash-container {
  position: fixed;
  bottom: 15px;
  left: 15px;
  width: 540px;
  max-width: calc(100vw - 10px);
  z-index: 3;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @include respondToWidth(t) {
    bottom: 5px;
    left: 5px;
  };

  .flash-item {
    width: 100%;
    display: flex;
    align-items: flex-start;
    color: $text-regular;
    padding: 1rem 0.75rem;
    margin-top: 16px;
    position: relative;

    .flash-button {
      display: flex;
      align-items: center;
      border: none;
      background-color: white;
      color: $text-regular;
      margin-left: 8px;
      border-radius: $border-radius;
      padding: 0.5rem 1rem;
      font-weight: bold;
      white-space: nowrap;

      i {
        color: $grey-light;
        margin-right: 8px;
      }

      &:focus,
      &.focus {
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
      }
    }

    .flash-icon {
      color: $white;
      font-size: 30px;
      margin-right: 10px;
      padding: 0.25rem 0;
    }

    .flash-text {
      color: $white;
      margin: 0;
      padding: 0.5rem 0;
      flex-grow: 1;
    }

    .flash-close {
      color: rgba(255,255,255,0.6);
      background-color: transparent;
      border: none;
      padding: 0.25rem;
      font-size: 30px;
      margin-left: 10px;

      &:hover {
        color: white;
      }
    }

    &:hover {
      .flash-bar {
        animation: none;
      }
    }

    .flash-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 8px;
      transform-origin: top left;
      transform: scaleX(1);
      animation-name: shrink;
      animation-duration: 7s;
      animation-fill-mode: forwards;
    }
    &.flash-sticky > .flash-bar {
      display: none;
    }

    @keyframes shrink {
      0% {
        transform: scaleX(1);
      }
      100% {
        transform: scaleX(0);
      }
    }

    &.f-info {
      background-color: #7E97A2;
      .flash-bar {
        background-color: #3E5967;
      }
    }
    &.f-alert {
      background-color: #47A9F8;
      .flash-bar {
        background-color: #1470F1;
      }
    }
    &.f-warning {
      background-color: #FFF1C0;
      .flash-bar {
        background-color: #FFB320;
      }
      .flash-icon {
        color: #FFB320;
      }
      .flash-text {
        color: $text-primary;
      }
    }
    &.f-danger {
      background-color: #FF7A47;
      .flash-bar {
        background-color: #FF3914;
      }
    }
    &.f-success {
      background-color: #3ECE8F;
      .flash-bar {
        background-color: #0FA650;
      }
    }
  }
}

.flash {
  position: absolute;
  top: 59px;
  right: 100px;
  z-index: 3;
  text-align: center;
  border-radius: $border-radius;
  display: block;
  border: $border-base-1;
  color: $text-regular;
  width: 350px;
  padding: 0.75rem;

  @include m('bottom') {
    @extend .flash;
    position: fixed;
    top: auto;
    bottom: 30px;
    left: 30px;
    right: auto;
    height: 51px;
    overflow: hidden;
  }

  @include m('left') {
    float: left;
    width: 38px;
    margin-right: 1rem;

    .pp_missing {
      background: $grey-lightest;
      width: 38px;
      height: 38px;
      line-height: 38px;
      font-size: 1.4em;
    }

    .pp_avatar {
      width: 38px;
      height: 38px;
      margin-top: 0px;
    }
  }

  @include m('right') {
    float: left;
    width: calc(350px - 2.5rem - 40px);
    line-height: 20px;
  }
}

.flash:hover {
  cursor: pointer;
  background: $white-dark;
  color: $text-regular;
  text-decoration: none;
}

.flash:hover.arrow-top-right:after {
  border-bottom-color: $white-dark;
}

.alert-success {
  background-color: #cff2e0;
  border-color: #bbedd4;
  color: #076335;

  &:hover {
    cursor: default;
    color: #076335;
    background-color: #cff2e0;
    border-color: #bbedd4;
  }
}

.alert-danger {
  color: #7b2c2d;
  background-color: #fbdddd;
  border-color: #facfd0;

  &:hover {
    cursor: default;
    color: #7b2c2d;
    background-color: #fbdddd;
    border-color: #facfd0;
  }
}

.flash.arrow-top-right:before, .flash.arrow-top-right:after {
  top: -17px;
}

.flash.arrow-top-right:before{
  border-bottom-color: $border-base-1;
}
