// TODO: Refactor namings
.has-icon, .has-icon-small, .has-icon-inline, .has-icon-inline-label, .has-icon-inline-label-select, .has-icon-label {
  position: relative;

  label i {
    position: relative;
    top: 0px;
    right: 0;
  }

  i {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .label-required {
    &:after {
      color: $red;
    }
  }
}

.has-custom-invalid {
  .label-required {
    &:after {
      color: $red;
    }
  }
}

.invalid-feedback {
  i {
    padding-top: 0.5em !important;
    padding-right: 0.25em !important;
  }
}

.has-icon-small i {
  top: 12px;
}

.has-icon-inline i, .has-icon-inline-label i {
  right: 30px;
}

.has-icon-inline-label i, .has-icon-label i {
  top: 43px;
}

.has-icon-inline-label-select i {
  display: none;
}

.label-required {
  display: block;

  &:after {
    float: right;
    content: "required";
    color: $text-secondary;
    font-size: 0.9em;
  }
}

.textarea-large {
  min-height: 150px;
}

.textarea-xl {
  min-height: 250px;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: none;
}

.radiobutton {
  width: 100%;
  position: relative;
  margin: 3px 0px;

  input[type=radio] {
    visibility: hidden;
  }

  label {
    margin-bottom: 0px;
  }

  &_label {
    cursor: pointer;
    float: left;
    width: 16px;
    height: 16px;
    min-height: 16px;
    background: $white;
    border: $border-base-1;
    border-radius: 50%;
    padding: 0;
    margin: 0;

    &:after {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      opacity: 0.2;
      background: transparent;
    }
  }

  &_label.is-disabled {
    cursor: default;
    visibility: hidden;
  }

  &_content {
    position: relative;
    top: -3px;
    width: calc(100% - 36px);
    margin-left: -6px;
    text-align: left;
    line-height: 21px;

    &:hover {
      cursor: pointer;
      color: $blue !important;
    }
  }

  &_content.is-disabled:hover {
    cursor: default;
    color: $text-regular !important;
  }

  &_counter {
    position: absolute;
    top: 0;
    right: -5px;
    left: 0;
    text-align: right;
    // font-size: 0.9em;
  }

  .note {
    padding-left: 24px;
  }

  &_icon {
    margin-right: 8px;

    &-checked {
      color: $blue !important;
    }
  }
}

.radiobutton .radiobutton_label:hover::after {
  opacity: 0.5;
}

.radiobutton input[type=radio]:checked + .radiobutton_label:after, .radiobutton input.active + .radiobutton_label:after{
  background: $blue;
  opacity: 1;
}

// .radiobutton input[type=radio]:disabled + .radiobutton_label:after {
//   background: $red;
//   opacity: 1;
// }

.radiobutton-wrapper.is-invalid ~ .invalid-feedback {
  display: block;
}

.checkbox2 {
  display: flex;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;

  &:hover {
    color: $blue;
  }

  input[type=checkbox] {
    appearance: none;
    font: inherit;
    color: currentColor;
    min-width: 17px;
    height: 17px;
    border: 1px solid $grey-lightest;
    background-color: $white;
    border-radius: $border-radius;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    margin-right: 8px;
    margin-top: 4px;
    cursor: pointer;
  }

  input[type=checkbox]::after {
    content: '';
    position: absolute;
    top: 3px;
    left: 6px;
    width: 9px;
    height: 5px;
    opacity: 0.2;
    background: transparent;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
  }

  input[type=checkbox]:checked::after {
    border: 2px solid $blue;
    border-top: none;
    border-right: none;
    opacity: 1;
    top: 4px;
    left: 3px;
  }

  input[type=checkbox]:disabled, &.is-disabled {
    color: $grey-light;
    cursor: not-allowed;
  }
}

// checkbox
.checkbox {
  display: flex;
  width: 100%;
  position: relative;
  margin: 3px 0px;

  &:hover, &:focus-within {
    cursor: pointer;
    color: $blue;
  }

  input[type=checkbox] {
    // visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
  }

  &_label {
    cursor: pointer;
    // float: left;
    position: relative;
    width: 17px;
    height: 17px;
    min-height: 17px;
    min-width: 17px;
    background: $white;
    border: $border-base-1;
    border-radius: $border-radius;
    padding: 0;
    margin: 0;

    &:after {
      content: '';
      position: absolute;
      top: 3px;
      left: 6px;
      width: 9px;
      height: 5px;
      opacity: 0.2;
      background: transparent;
      border-top: none;
      border-right: none;
      transform: rotate(-45deg);
    }
  }

  &_label.is-disabled {
    cursor: default;
    background: $grey-lightest;
  }


  &_content.is-disabled:hover {
    cursor: default;
    color: $text-regular !important;
  }


  &_content {
    position: relative;
    top: -2px;
    max-width: calc(100% - 24px);
    margin-left: 8px;
    text-align: left;
    line-height: 21px;

    &:hover {
      cursor: pointer;
      color: $blue !important;
    }
  }

  &_name {
    padding-right: 30px;

    &--with-counts {
      padding-right: 80px;
    }
  }

  &_icon {
    margin-right: 8px;

    &-checked {
      color: $blue !important;
    }
  }

  &_description {
    margin-bottom: 8px;
  }

  &_counter {
    position: absolute;
    top: 0;
    right: -5px;
    left: 0;
    text-align: right;
    // font-size: 0.9em;
  }

  &_link {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    text-align: right;
    font-size: 0.9em;
    background-color: $white;
  }

  &:hover {
    .checkbox_link {
      display: block;
    }
  }
}

.checkbox .checkbox_label:hover::after {
  opacity: 0.5;
}

.checkbox input[type=checkbox]:checked + .checkbox_label:after {
  border: 2px solid $blue;
  border-top: none;
  border-right: none;
  opacity: 1;
  top: 4px;
  left: 3px;
}

.checkbox input[type=checkbox]:checked + .checkbox_content {
  color: $blue;
}

.checkbox-wrapper.is-invalid ~ .invalid-feedback {
  display: block;
}

.typeahead-wrapper.is-invalid ~ .invalid-feedback {
  display: block;
}

.typeahead-wrapper.is-invalid > .input-group > .form-control {
  border: $border-danger-1;
}

.typeahead-wrapper.is-invalid .input-group-addon .fa {
  margin-top: 11px;
  top: 0px;
  left: 15px;
}

.typeahead-wrapper .input-group-addon.form-control-lg i {
  margin-left: -3px;
  margin-top: 7px;
}

.richtext-wrapper.is-invalid {
  .invalid-feedback {
    display: block;
  }
}

.richtext-wrapper.is-invalid {
  .ql-toolbar.ql-snow {
    border-color: $red !important;
  }
}

.richtext-wrapper.is-invalid {
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-color: $red  !important;;
  }
}

.richtext-wrapper.is-invalid .input-group-addon .fa {
  margin-top: 11px;
  top: 0px;
  left: 15px;
}


// select label
select {
  border: $border-base-1;
  background: transparent;
  padding: 5px 35px 5px 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}
.form-control {
  color: $text-regular;
}

.select-label {
  position: relative;
  width: 100%;
  width: 100%;
  margin-bottom: 0px;
  color: $text-regular;

  &:after {
    content: "\f078";
    font-family: "FontAwesome";
    position:absolute;
    top: 7px;
    font-size: 0.8em;
    color: $text-secondary;
    right: 15px;
    pointer-events: none;
  }

  @include m('small') {
    @extend .select-label;

    &:after {
      top: 7px;
    }
  }
}

.select-label--small.is-invalid {
  select {
    border: $border-danger-1;
  }
}

select::-ms-expand {
  display: none;
}

/* target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width:0\0) {
  select {
    background:none\9;
    padding: 5px\9;
  }
}

// new indeterminate checkbox (3 states: true, false, indeterminate)
.indeterminate-checkbox {
  &__wrapper {
    input[type=checkbox]:checked + .indeterminate-checkbox__checkbox::after {
      font-family: "FontAwesome";
      position: absolute;
      top: 54%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: "\f00c";
      color: $blue;
      font-size: 12px;
    }
    input[type=checkbox]:indeterminate + .indeterminate-checkbox__checkbox::after {
      font-family: "FontAwesome";
      position: absolute;
      top: 54%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: "\f068";
      color: $blue;
      font-size: 12px;
    }
    input {
      display: none;
    }
  }
  &__checkbox {
    width: 17px;
    height: 17px;
    border: 2px solid $grey-light;
    border-radius: 4px;
    margin-right: 2px;
    position: relative;
    background: $white;
  }
}