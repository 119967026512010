// fonts
$fa-font-path: "~font-awesome/fonts/";
@font-face {
    font-family: 'Figtree';
    src: url('../fonts/Figtree-Regular.woff2') format('woff2'),
        url('../fonts/Figtree-Regular.woff') format('woff'),
        url('../fonts/Figtree-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Figtree';
    src: url('../fonts/Figtree-Medium.woff2') format('woff2'),
        url('../fonts/Figtree-Medium.woff') format('woff'),
        url('../fonts/Figtree-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Figtree';
    src: url('../fonts/Figtree-SemiBold.woff2') format('woff2'),
        url('../fonts/Figtree-SemiBold.woff') format('woff'),
        url('../fonts/Figtree-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Figtree';
    src: url('../fonts/Figtree-Bold.woff2') format('woff2'),
        url('../fonts/Figtree-Bold.woff') format('woff'),
        url('../fonts/Figtree-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

// global variables
@import "./_loaders/colors";
@import "./_loaders/variables";

// npm imports
@import "~font-awesome/css/font-awesome.min.css";
@import "~vue-multiselect/dist/vue-multiselect.min.css";

@import "./al-custom-icons/style";

// bootstrap source file.
@import "~bootstrap/scss/bootstrap";
@import "./_partials/mixins";

// html elements
@import "./_partials/elements";
@import "./_partials/container";
@import "./_partials/badge";
@import "./_partials/button";
@import "./_partials/classes";
@import "./_partials/dropdown";
@import "./_partials/modal";
@import "./_partials/table";
@import "./_partials/text";
@import "./_partials/tooltip";
@import "./_partials/border";
@import "./_partials/form";
@import "./_partials/background";
@import "./_partials/custom-tooltip";
@import "./_partials/icons";
@import "./_partials/grid";

// effects
@import "./_partials/effects/waves";
@import "./_partials/effects/highlight";
@import "./_partials/effects/spinner";
@import "./_partials/effects/fade";
@import "./_partials/effects/notification";

// components
@import "./components/avatar";
@import "./components/chart";
@import "./components/header";
@import "./components/footer";
@import "./components/flash";
@import "./components/placeholder";
@import "./components/profilepicture";
@import "./components/timeline";
@import "./components/account-completion";
@import "./components/step-list";
@import "./components/datepicker";
@import "./components/invitation-tabs";
@import "./components/organisation-request-accept";
@import "./components/dialog";
@import "./components/horizontal-list";
@import "./components/cookie-notification";
@import "./components/profiles-compact";
@import "./components/categories";
@import "./components/pagination";
@import "./components/tabs";
@import "./components/scite";
@import "./components/speech-bubble";

// components form
@import "./components/form/switches";
@import "./components/form/typeahead";
@import "./components/form/quill-editor";
@import "./components/form/input-tag";
@import "./components/form/range-slider";
@import "./components/form/multiselect";

// pages
@import "./views/create";
@import "./views/error-page";
@import "./views/login-page";
@import "./views/scoped-view";
@import "./views/shared";
@import "./views/search";
@import "./views/researcher";
@import "./views/question";
@import "./views/community";
@import "./views/register";
@import "./views/settings";
@import "./views/opportunity";
@import "./views/landscape";
