// Range Slider
$range-width: 50% !default;
$range-handle-size: 18px !default;
$range-track-height: 5px !default;

.range-slider {
  width: $range-width;
  margin-left: 25%;
}

.range-slider__range {
  -webkit-appearance: none;
  height: $range-track-height;
  border-radius: 5px;
  background: $grey-lighter;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;

  // Range Handle
 &::-webkit-slider-thumb {
    cursor: pointer;
    -webkit-appearance: none;
    border: $border-base-1;
    width: $range-handle-size;
    height: $range-handle-size;
    border-radius: 50%;
    background: $white-dark;
    transition: background .15s ease-in-out;

    &:hover {
      background: $blue;
    }
  }

  &:active::-webkit-slider-thumb {
    background: $blue;
  }

  &::-moz-range-thumb {
    cursor: pointer;
    width: $range-handle-size;
    height: $range-handle-size;
    border: 0;
    border-radius: 50%;
    background: $white-dark;
    transition: background .15s ease-in-out;

    &:hover {
      background: $blue;
    }
  }

  &:active::-moz-range-thumb {
    background: $blue;
  }

  &::-ms-thumb {
    cursor: pointer;
    appearance: none;
    width: $range-handle-size;
    height: $range-handle-size;
    border-radius: 50%;
    background: $white;
    transition: background .15s ease-in-out;

    &:hover {
      background: $blue;
    }
  }

  &:active::-ms-thumb {
    background: $blue;
  }
}

// Firefox Overrides
::-moz-range-track {
    background: $grey-lighter;
    border: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}
