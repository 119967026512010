.btn {
  position: relative;

  &.disabled,
  &:disabled {
    cursor: not-allowed!important;
  }

}

.btn-form {
  min-width: 120px;
  height: 38px;
  line-height: 38px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  transition: all .3s ease;
  overflow: hidden;

  span {
    display: block;
  }

  .pre-state-msg {
    transition: all .7s cubic-bezier(0.770, 0.000, 0.175, 1.000);
    transition-delay: .3;
  }

}

.dots {
  &:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;
  }
}

.smooth-away {
  margin-top: -38px;
}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0)
  }

  40% {
    color: white;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0)
  }

  60% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 rgba(0,0,0,0)
  }

  80%, 100% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 white
  }
}

.btn-primary {
  white-space: nowrap;
  outline: none;

  &:hover {
    box-shadow: $box-shadow-bottom-right;
    background: $blue-dark;
    cursor: pointer;
  }

  &:active {
    background: $white !important;
    outline: none !important;
    color: $blue !important;
  }

  a {
    outline: none;
  }

  // --green
  @include m('green') {
    @extend .btn-primary;
    background: $green-light;
    border: $border-green-light-1 !important;
  }

  // --red
  @include m('red') {
    @extend .btn-primary;
    background: $red;
    color: $white;
    border: $border-danger-1;
  }

  // --red
  @include m('yellow') {
    @extend .btn-primary;
    background: $yellow;
    color: $white;
    border: $border-yellow-1;
  }

  @include m('yellow-light') {
    @extend .btn-primary;
    background: $yellow-light;
    color: $white !important;
    border: $border-yellow-1;
  }

  @include m('green-dark') {
    @extend .btn-primary;
    background: $green;
    color: $white;
    border: $border-green-1;

    &:hover, &:active, &:focus {
      background: $green !important;
      color: $white;
      border: $border-green-1 !important;
    }
  }

  @include m('inverted') {
    @extend .btn-primary;
    background: transparent;
    color: $white;
    border: $border-white-1;

    &:hover, &:active, &:focus {
      background: $white;
      color: $blue;
    }
  }
}

.btn-primary--green-dark.box-shadow {
  width: calc(100% - 2px) !important;
}

.btn-secondary {
  border: $border-blue-1;
  background: $white;
  color: $blue;
  white-space: nowrap;
  outline: none;

  &:hover {
    cursor: pointer;
    background: $white;
    box-shadow: $box-shadow-bottom-right;
    color: $blue;
    border: $border-blue-1;
  }

  &:active {
    background: $white !important;
    outline: none !important;
    color: $blue !important;
  }

  // --grey
  @include m('grey') {
    @extend .btn-secondary;
    color: $text-regular;
    border: $border-base-1;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(59, 153, 240, 0.25);
    }
  }

  // --grey
  @include m('grey-blueborder') {
    @extend .btn-secondary;
    color: $text-regular;
    border: $border-blue-1;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(59, 153, 240, 0.25);
    }
  }

  @include m('red') {
    @extend .btn-secondary;
    color: $text-regular;
    border: $border-danger-1;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(59, 153, 240, 0.25);
    }
  }

  @include m('green') {
    @extend .btn-secondary;
    color: $text-regular;
    border: $border-green-1;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(59, 153, 240, 0.25);
    }

    &:hover {
      color: $text-regular;
      border: $border-green-1;
    }
  }

  @include m('yellow') {
    @extend .btn-secondary;
    color: $yellow;
    border: $border-yellow-1;

    &:hover {
      color: $yellow;
      border: $border-yellow-1;
    }

    &:active {
      color: $yellow !important;
    }

    &:focus {
      outline: 0;
    }
  }
}

.btn--icon {
  padding: 8px 34px;
  i {
    margin-right: 4px;
    color: $green-light;
  }
}

.btn-secondary--grey.is-disabled {
  &:hover {
    transform: none;
    color: $text-regular;
    border: $border-base-1;
    box-shadow: none;
    cursor: not-allowed;

    &:active {
      color: $text-regular !important;
    }
  }

  @include m('border') {
    border: $border-blue-1;
    opacity: 1;
    background: $white-dark;
    pointer-events: none;
  }
}

.btn-secondary.open {
  &:hover {
    transform: none;
    box-shadow: none;
  }
}

.btn-select-menu {
  border: $border-base-1;
  background: $white;
  min-width: 60px;
  min-height: 38px;
  text-align: left;

  &:after {
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: -2px;
    content: "";
    border: 4px solid;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    position: absolute;
    right: 10px;
    top: 17px;
  }

  i {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  span {
    padding-left: 22px;
  }

  &:hover:not(:disabled) {
    background: $white-dark;
  }
}

.btn-select-menu.open {
  border-color: rgba(27,31,35,0.25);
  box-shadow: inset 0 0.15em 0.3em rgba(27,31,35,0.15);
}

.btn-login-option {
  width: 100%;
  height: 42px;
  padding: 0px;
  border: $border-base-1;
  text-align: left;
  background:  $white;
  white-space: nowrap;
  outline: none;

  i {
    display: inline-block;
    float: left;
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0 0.75rem;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    border-right: $border-base-1;
  }

  span {
    display: inline-block;
    float: left;
    width: calc(100% - 40px);
    height: 40px;
    line-height: 40px;
    padding: 0 1em;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  &:hover {
    box-shadow: $box-shadow-bottom-right;
    cursor: pointer;
  }
}

.btn-input-file {
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.btn-arrow-right {
  &:after {
    content: "\f078";
    font-family: "FontAwesome";
    position:absolute;
    top: 8px;
    font-size: 0.8em;
    color: $text-secondary;
    right: 15px;
    pointer-events: none;
  }
}

.label-base {
  font-size: 10px;
  font-weight: 600;
  line-height: 10px;
  color: $white;
  padding: 5px 8px;
  border-radius: 4px;
}

.label-filter-header {
  margin-top: -2px;
  margin-bottom: -2.5px;
  height: 20px;
  display: inline-block;
}

.label-search-result {
  position: absolute;
  top: 12px;
  right: 20px;
}

.btn-search-suggestion:hover {
  i {
    color: white !important;
  }
}